<template>
  <div id="OCR">
    <div class="OCRBox">
      <div class="OCRTop">
        <div class="title">
          <img
            src="@/assets/img/head/icon.png"
            style="margin-bottom: 5px; margin-right: 5px"
          />
          基本信息
        </div>
        <div class="pl-4 pt-3 pb-3">
          <el-row>
            <el-col :span="6" class="row"
              ><span class="TitleText">公司名称</span>
              <div class="grid-content bg-purple ml-2">{{ company_name }}</div>
            </el-col>
            <el-col :span="6" class="row"
              ><span class="TitleText">报表期间</span>
              <div class="grid-content bg-purple-light ml-2">
                {{ dataList.report_year }}年
                <span v-show="dataList.report_time == '00'">年报</span>
                <span v-show="dataList.report_time == '01'">三季报</span>
                <span v-show="dataList.report_time == '02'">半年报</span>
                <span v-show="dataList.report_time == '03'">一季报</span>
                <span v-show="dataList.report_time == '001'">一月报</span>
                <span v-show="dataList.report_time == '002'">二月报</span>
                <span v-show="dataList.report_time == '003'">三月报</span>
                <span v-show="dataList.report_time == '004'">四月报</span>
                <span v-show="dataList.report_time == '005'">五月报</span>
                <span v-show="dataList.report_time == '006'">六月报</span>
                <span v-show="dataList.report_time == '007'">七月报</span>
                <span v-show="dataList.report_time == '008'">八月报</span>
                <span v-show="dataList.report_time == '009'">九月报</span>
                <span v-show="dataList.report_time == '010'">十月报</span>
                <span v-show="dataList.report_time == '011'">十一月报</span>
                <span v-show="dataList.report_time == '012'">十二月报</span>
              </div>
            </el-col>
            <el-col :span="6" class="row"
              ><span class="TitleText">报表种类</span>
              <div class="grid-content bg-purple-light ml-2">
                <span v-if="dataList.report_type == '00'"> 合并报表 </span>
                <span v-else> 本部报表 </span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="OCRcont">
        <div class="title">
          <img
            src="@/assets/img/head/icon.png"
            style="margin-bottom: 5px; margin-right: 5px"
          />
          报表OCR识别
        </div>
        <div class="mt-3 row justify-content-between">
          <div class="imgBox">
            <div
              style="color: #ccc"
              class="mb-2 row align-items-center justify-content-between"
            >
              <div class="row align-items-center">
                <a
                  style="color: #3a85fd; font-size: 22px; margin-right: 20px"
                  @click="imgScaleHandle(0.25)"
                  ><i class="el-icon-zoom-in"></i
                ></a>
                |
                <a
                  style="
                    color: #3a85fd;
                    font-size: 22px;
                    margin-left: 20px;
                    margin-right: 20px;
                  "
                  @click="imgScaleHandle(-0.25)"
                  ><i class="el-icon-zoom-out"></i></a
                >|
                <a
                  style="
                    color: #3a85fd;
                    font-size: 22px;
                    margin-left: 20px;
                    margin-right: 20px;
                  "
                  @click="handleRotate"
                  ><i class="el-icon-refresh-right"></i></a
                >|
                <a
                  style="
                    color: #3a85fd;
                    font-size: 22px;
                    margin-left: 20px;
                    margin-right: 20px;
                  "
                  @click="handleReset"
                  ><i class="el-icon-refresh"></i
                ></a>
              </div>
              <div>
                <el-button
                  type="primary"
                  size="small"
                  icon="el-icon-upload2"
                  @click="anewUpload"
                  >重新上传</el-button
                >
              </div>
            </div>
            <div class="imgCont" ref="maskBox" @mousedown="onmousedownHandle">
              <img
                :src="imageUrl"
                alt=""
                :style="{
                  width: imgW + 'px',
                  height: imgH + 'px',
                  top: top + 'px',
                  left: left + 'px',
                  transform: scale,
                }"
              />
            </div>
          </div>
          <div class="tableBox">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane
                v-for="(item, key, index) in dataList.content"
                :key="index"
                :label="item.fs_type"
                :name="item.fs_type"
              >
                <div v-if="index == 0">
                  <el-table
                    :header-cell-style="{
                      background: '#E9F3FF',
                      color: '#333',
                    }"
                    style="width: 100%; color: #333; font-size: 16px"
                    :data="item.table_data"
                    height="535px"
                  >
                    <el-table-column
                      prop="template_name"
                      label="客户上传报表科目"
                    >
                      <template slot-scope="{ row }">
                        <div v-if="row.formula == undefined">
                          {{ row.template_name }}
                        </div>

                        <div
                          v-else
                          :style="
                            row.formula[0].error == 0 &&
                            row.formula[1].error == 0
                              ? {
                                  'font-size': '14px',
                                  color: ' #56bd81',
                                  'font-weight': 550,
                                }
                              : {
                                  'font-size': '14px',
                                  color: ' #f14c5d',
                                  'font-weight': 550,
                                }
                          "
                        >
                          {{ row.template_name }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="数行标准报表科目">
                      <template slot-scope="{ row }">
                        <div v-if="row.formula == undefined">
                          <el-select
                            size="mini"
                            v-model="row.subject"
                            filterable
                            placeholder="请选择"
                            ref="getSelect"
                            @change="
                              changeOption(
                                row,
                                $event,
                                row.recommend,
                                item.list_data.no_total,
                                item.list_data.total
                              )
                            "
                          >
                            <el-option-group
                              v-for="group in option(
                                row.recommend,
                                item.list_data.no_total,
                                item.list_data.total
                              )"
                              :key="group.label"
                              :label="group.label"
                            >
                              <el-option
                                v-for="item in group.options"
                                :key="item.subject"
                                :label="item.name"
                                :value="item.subject"
                              >
                              </el-option>
                            </el-option-group>
                          </el-select>
                        </div>
                        <div v-else>
                          <el-select
                            size="mini"
                            v-model="row.name"
                            filterable
                            placeholder="请选择"
                            ref="getSelect"
                            :disabled="
                              row.name !== '' && row.fixed == 1 ? true : false
                            "
                            :class="
                              row.formula[0].error !== 0 ||
                              row.formula[1].error !== 0
                                ? 'err'
                                : 'suc'
                            "
                            @change="
                              changeOptionTotal(
                                row,
                                $event,
                                row.recommend,
                                item.list_data.no_total,
                                item.list_data.total
                              )
                            "
                          >
                            <el-option-group
                              v-for="group in option(
                                row.recommend,
                                item.list_data.no_total,
                                item.list_data.total
                              )"
                              :key="group.label"
                              :label="group.label"
                            >
                              <el-option
                                v-for="item in group.options"
                                :key="item.subject"
                                :label="item.name"
                                :value="item.name"
                              >
                              </el-option>
                            </el-option-group>
                          </el-select>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="年初余额（元）">
                      <template slot-scope="{ row }">
                        <div v-if="row.formula == undefined">
                          <el-input-number
                            :precision="2"
                            :min="-Infinity"
                            :max="Infinity"
                            :controls="false"
                            placeholder="请输入"
                            size="mini"
                            style="width: 80%"
                            v-model="row.value1"
                            @change="changeValue1"
                          >
                          </el-input-number>
                        </div>

                        <el-popover
                          v-else
                          placement="left"
                          width="550"
                          trigger="hover"
                        >
                          <div class="row">
                            <div style="width: 75%">
                              <div
                                style="
                                  color: #333;
                                  padding: 5px;
                                  background: #e9f3ff;
                                "
                              >
                                配平公式
                              </div>
                              <div style="padding: 5px">
                                {{ row.formula[1].formula }}
                              </div>
                            </div>
                            <div style="width: 25%">
                              <div
                                style="
                                  color: #333;
                                  padding: 5px;
                                  background: #e9f3ff;
                                "
                              >
                                误差
                              </div>
                              <div style="padding: 5px">
                                {{ row.formula[1].error }}
                              </div>
                            </div>
                          </div>
                          <div slot="reference">
                            <el-input-number
                              :precision="2"
                              :min="-Infinity"
                              :max="Infinity"
                              :controls="false"
                              placeholder="请输入"
                              size="mini"
                              style="width: 80%; color: red"
                              v-model="row.value1"
                              @change="changeValue1"
                              :class="
                                row.formula[0].error !== 0 ||
                                row.formula[1].error !== 0
                                  ? 'err'
                                  : 'suc'
                              "
                            >
                            </el-input-number>
                          </div>
                        </el-popover>
                      </template>
                    </el-table-column>
                    <el-table-column label="期末余额（元）">
                      <template slot-scope="{ row }">
                        <div v-if="row.formula == undefined">
                          <el-input-number
                            :precision="2"
                            :min="-Infinity"
                            :max="Infinity"
                            :controls="false"
                            placeholder="请输入"
                            size="mini"
                            style="width: 80%"
                            v-model="row.value"
                            @change="changeValue"
                          >
                          </el-input-number>
                        </div>

                        <el-popover
                          v-else
                          placement="left"
                          width="550"
                          trigger="hover"
                          style="z-index: 9999"
                        >
                          <div class="row">
                            <div style="width: 75%">
                              <div
                                style="
                                  color: #333;
                                  padding: 5px;
                                  background: #e9f3ff;
                                "
                              >
                                配平公式
                              </div>
                              <div style="padding: 5px">
                                {{ row.formula[0].formula }}
                              </div>
                            </div>
                            <div style="width: 25%">
                              <div
                                style="
                                  color: #333;
                                  padding: 5px;
                                  background: #e9f3ff;
                                "
                              >
                                误差
                              </div>
                              <div style="padding: 5px">
                                {{ row.formula[0].error }}
                              </div>
                            </div>
                          </div>
                          <div slot="reference">
                            <el-input-number
                              :precision="2"
                              :min="-Infinity"
                              :max="Infinity"
                              :controls="false"
                              placeholder="请输入"
                              size="mini"
                              style="width: 80%"
                              v-model="row.value"
                              @change="changeValue"
                              :class="
                                row.formula[0].error !== 0 ||
                                row.formula[1].error !== 0
                                  ? 'err'
                                  : 'suc'
                              "
                            >
                            </el-input-number>
                          </div>
                        </el-popover>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                      <template slot-scope="{ row }">
                        <el-button
                          style="height: 20px; font-size: 14px"
                          type="text"
                          size="small"
                          :disabled="row.fixed ? true : false"
                          :style="
                            row.fixed ? { color: '#999' } : { color: '#3a85fd' }
                          "
                          @click="deleteClick(row, item.table_data)"
                          >删除</el-button
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div v-else>
                  <el-table
                    :header-cell-style="{
                      background: '#E9F3FF',
                      color: '#333',
                    }"
                    style="width: 100%; color: #333; font-size: 16px"
                    :data="item.table_data"
                    height="535px"
                  >
                    <el-table-column
                      prop="template_name"
                      label="客户上传报表科目"
                    >
                      <template slot-scope="{ row }">
                        <div v-if="row.formula == undefined">
                          {{ row.template_name }}
                        </div>

                        <div
                          v-else
                          :style="
                            row.formula[0].error == 0
                              ? {
                                  'font-size': '14px',
                                  color: ' #56bd81',
                                  'font-weight': 550,
                                }
                              : {
                                  'font-size': '14px',
                                  color: ' #f14c5d',
                                  'font-weight': 550,
                                }
                          "
                        >
                          {{ row.template_name }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="数行标准报表科目">
                      <template slot-scope="{ row }">
                        <div v-if="row.formula == undefined">
                          <el-select
                            size="mini"
                            v-model="row.subject"
                            filterable
                            placeholder="请选择"
                            ref="getSelect"
                            @change="
                              changeOption(
                                row,
                                $event,
                                row.recommend,
                                item.list_data.no_total,
                                item.list_data.total
                              )
                            "
                          >
                            <el-option-group
                              v-for="group in option(
                                row.recommend,
                                item.list_data.no_total,
                                item.list_data.total
                              )"
                              :key="group.label"
                              :label="group.label"
                            >
                              <el-option
                                v-for="item in group.options"
                                :key="item.subject"
                                :label="item.name"
                                :value="item.subject"
                              >
                              </el-option>
                            </el-option-group>
                          </el-select>
                        </div>
                        <div v-else>
                          <el-select
                            size="mini"
                            v-model="row.name"
                            filterable
                            placeholder="请选择"
                            ref="getSelect"
                            :disabled="
                              row.name !== '' && row.fixed == 1 ? true : false
                            "
                            :class="row.formula[0].error !== 0 ? 'err' : 'suc'"
                            @change="
                              changeOptionTotal(
                                row,
                                $event,
                                row.recommend,
                                item.list_data.no_total,
                                item.list_data.total
                              )
                            "
                          >
                            <el-option-group
                              v-for="group in option(
                                row.recommend,
                                item.list_data.no_total,
                                item.list_data.total
                              )"
                              :key="group.label"
                              :label="group.label"
                            >
                              <el-option
                                v-for="item in group.options"
                                :key="item.subject"
                                :label="item.name"
                                :value="item.name"
                              >
                              </el-option>
                            </el-option-group>
                          </el-select>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="本年数">
                      <template slot-scope="{ row }">
                        <div v-if="row.formula == undefined">
                          <el-input-number
                            :precision="2"
                            :min="-Infinity"
                            :max="Infinity"
                            :controls="false"
                            placeholder="请输入"
                            size="mini"
                            style="width: 80%"
                            v-model="row.value"
                            @change="changeValue"
                          >
                          </el-input-number>
                        </div>

                        <el-popover
                          v-else
                          placement="left"
                          width="550"
                          trigger="hover"
                          style="z-index: 9999"
                        >
                          <div class="row">
                            <div style="width: 75%">
                              <div
                                style="
                                  color: #333;
                                  padding: 5px;
                                  background: #e9f3ff;
                                "
                              >
                                配平公式
                              </div>
                              <div style="padding: 5px">
                                {{ row.formula[0].formula }}
                              </div>
                            </div>
                            <div style="width: 25%">
                              <div
                                style="
                                  color: #333;
                                  padding: 5px;
                                  background: #e9f3ff;
                                "
                              >
                                误差
                              </div>
                              <div style="padding: 5px">
                                {{ row.formula[0].error }}
                              </div>
                            </div>
                          </div>
                          <div slot="reference">
                            <el-input-number
                              :precision="2"
                              :min="-Infinity"
                              :max="Infinity"
                              :controls="false"
                              placeholder="请输入"
                              size="mini"
                              style="width: 80%"
                              v-model="row.value"
                              @change="changeValue"
                              :class="
                                row.formula[0].error !== 0 ? 'err' : 'suc'
                              "
                            >
                            </el-input-number>
                          </div>
                        </el-popover>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                      <template slot-scope="{ row }">
                        <el-button
                          style="height: 20px; font-size: 14px"
                          type="text"
                          size="small"
                          :disabled="row.fixed ? true : false"
                          :style="
                            row.fixed ? { color: '#999' } : { color: '#3a85fd' }
                          "
                          @click="deleteClick(row, item.table_data)"
                          >删除</el-button
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div class="mt-3" style="margin-left: 90px">
                  <el-button
                    class="ml-5"
                    size="small"
                    style="
                      width: 120px;
                      border-color: #3a85fd;
                      color: #3a85fd;
                      font-size: 14px;
                    "
                    @click="reset(item.fs_type)"
                    >重 置</el-button
                  >
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="当前按钮只是确认当前页面数据，并不表示提交数据"
                    placement="top"
                  >
                    <el-button
                      size="small"
                      type="primary"
                      style="width: 120px; font-size: 14px"
                      class="ml-5"
                      @click="confirm(item.fs_type, item.is_trim)"
                      >确认数据</el-button
                    >
                  </el-tooltip>
                </div>
              </el-tab-pane>
            </el-tabs>
            <div class="text">
              <div class="rowTitle">
                <div class="textbox correct"></div>
                <div style="color: #56bd81">配平成功</div>
              </div>
              <div class="rowTitle">
                <div class="textbox red"></div>
                <div style="color: #f14c5d">配平错误</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="OCRfooter">
      <el-button
        class="ml-5"
        size="small"
        style="
          font-size: 14px;
          width: 120px;
          border-color: #3a85fd;
          color: #3a85fd;
        "
        @click="escClick"
        >取 消</el-button
      >

      <el-button
        type="primary"
        size="small"
        class="ml-4"
        @click="onSubmit"
        style="font-size: 14px"
        :disabled="check"
        >提交报表数据</el-button
      >
    </div>
    <el-dialog
      title="温馨提示"
      :visible.sync="checkDialog"
      width="35%"
      :before-close="handleClose"
    >
      <span style="font-size: 14px; color: #333" v-html="checkDesc"></span>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="small"
          style="
            font-size: 14px;
            width: 120px;
            border-color: #3a85fd;
            color: #3a85fd;
          "
          @click="checkDialog = false"
          >取 消</el-button
        >
        <el-button
          style="font-size: 14px; width: 120px; border-color: #3a85fd"
          size="small"
          type="primary"
          @click="Submit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as sesstion from "../../store/localStorage";
import { checkParam, checkBalance, save, coverage } from "@/api";
export default {
  name: "OCR",
  data() {
    return {
      activeName: "资产负债表",
      company_name: "",
      dataList: {},

      imageUrl: "",
      imageUrl1: require("@/assets/img/sign/person.png"),
      imageUrl2: require("@/assets/img/sign/sign.png"),
      imageUrl3: require("@/assets/img/sign/sign.png"),
      imgW: 0,
      imgH: 0,
      deg: 0,
      top: 0,
      left: 0,
      scale: "scale(1)",
      size: 0,

      mousewheelevt: null,
      FB: false,
      FP: false,
      FC: false,

      check: true,
      checkDialog: false,
      checkDesc: "",
    };
  },

  created() {
    this.$uploadLoadingShow();
    setTimeout(() => {
      this.$uploadLoadingHide();
    }, 3000);
    this.company_name = sesstion.getLocalData("company").name; //客户名
    this.dataList = sesstion.getLocalData("OCRList");
    this.imageUrl1 =
      window.BASE_URL + sesstion.getLocalData("OCRList").content.fb.uri;
    this.imageUrl2 =
      window.BASE_URL + sesstion.getLocalData("OCRList").content.fp.uri;
    this.imageUrl3 =
      window.BASE_URL + sesstion.getLocalData("OCRList").content.fc.uri;
  },

  mounted() {
    this.imageUrl = this.imageUrl1;
    //初始化图片this.initImage();
    // 兼容火狐浏览器
    this.mousewheelevt = /Firefox/i.test(navigator.userAgent)
      ? "DOMMouseScroll"
      : "mousewheel";
    // 为空间区域绑定鼠标滚轮事件 =》 处理函数是wheelHandle// 如果你监听了window的scroll或者touchmove事件，你应该把passive设置为true，这样滚动就会流畅很多
    this.$refs.maskBox.addEventListener(this.mousewheelevt, this.wheelHandle, {
      passive: true,
    });
    this.handleReset();
  },

  beforeDestroy() {
    //取消监听
    this.$refs.maskBox.removeEventListener(
      this.mousewheelevt,
      this.wheelHandle,
      {
        passive: true,
      }
    );
  },

  methods: {
    handleClick(tab, event) {
      // console.log(tab, event);
      if (tab.label == "资产负债表") {
        this.imageUrl = this.imageUrl1;
      }
      if (tab.label == "利润表") {
        this.imageUrl = this.imageUrl2;
      }
      if (tab.label == "现金流量表") {
        this.imageUrl = this.imageUrl3;
      }
    },

    /** * 切换图片 *flag: 1竖图 2 横图 */
    // switchImgHandle(flag) {
    //   if (flag === 1) {
    //     this.imageUrl = this.imageUrl1;
    //   } else {
    //     this.imageUrl = this.imageUrl2;
    //   }
    //   this.handleReset();
    // },
    /** * 获取图片的url * 
   \* @param {string} url */
    getImgSize(url) {
      return new Promise((resolve, reject) => {
        let imgObj = new Image();
        imgObj.src = url;
        imgObj.onload = () => {
          resolve({
            width: imgObj.width,
            height: imgObj.height,
          });
        };
      });
    },
    /** * 初始化图片 */
    async initImage() {
      if (!this.imageUrl) {
        return;
      }
      let { width, height } = await this.getImgSize(this.imageUrl);
      // 设置原始图片的大小
      let realWidth = width;
      let realHeight = height;
      // 获取高宽比例
      const whRatio = realWidth / realHeight;
      const hwRatio = realHeight / realWidth;
      //获取盒子的大小
      const boxW = this.$refs.maskBox.clientWidth;
      const boxH = this.$refs.maskBox.clientHeight;
      if (realWidth >= realHeight) {
        this.imgH = hwRatio * boxW;
        const nih = this.imgH;
        if (nih > boxH) {
          this.imgH = boxH;
          this.imgW = whRatio * boxH;
        } else {
          this.imgW = boxW;
        }
        this.top = (boxH - this.imgH) / 2;
        this.left = (boxW - this.imgW) / 2;
      } else {
        this.imgW = (boxH / realHeight) * realWidth;
        this.imgH = boxH;
        this.left = (boxW - this.imgW) / 2;
      }
    },
    /** * 旋转 */
    handleRotate() {
      this.deg += 90;
      if (this.deg >= 360) {
        this.deg = 0;
      }
      this.size = 0;
      this.scale = `scale(1) rotateZ(${this.deg}deg)`;
    },
    /** * 图片的缩放 *zoom >0 放大 *zoom <0 缩小 */
    imgScaleHandle(zoom) {
      this.size += zoom;
      if (this.size < -0.5) {
        this.size = -0.5;
      }
      this.scale = `scale(${1 + this.size}) rotateZ(${this.deg}deg)`;
    },
    /** * 重置 */
    handleReset() {
      this.imgW = 0;
      this.imgH = 0;
      this.top = 0;
      this.left = 0;
      this.deg = 0;
      this.scale = "scale(1)";
      this.size = 0;
      this.initImage();
    },
    /** * 鼠标滚动 实现放大缩小 */
    wheelHandle(e) {
      const ev = e || window.event;
      // 兼容性处理 => 火狐浏览器判断滚轮的方向是属性 detail，谷歌和ie浏览器判断滚轮滚动的方向是属性 wheelDelta// dir = -dir;
      // dir > 0 => 表示的滚轮是向上滚动，否则是向下滚动 => 范围 (-120 ~ 120)
      const dir = ev.detail ? ev.detail * -120 : ev.wheelDelta;
      //滚动的数值 / 2000 => 表示滚动的比例，用此比例作为图片缩放的比例
      this.imgScaleHandle(dir / 2000);
    },
    /** * 处理图片拖动 */
    onmousedownHandle(e) {
      const that = this;
      this.$refs.maskBox.onmousemove = function (el) {
        const ev = el || window.event;
        // 阻止默认事件
        ev.preventDefault();
        that.left += ev.movementX;
        that.top += ev.movementY;
      };
      this.$refs.maskBox.onmouseup = function () {
        // 鼠标抬起时将操作区域的鼠标按下和抬起事件置为null 并初始化
        that.$refs.maskBox.onmousemove = null;
        that.$refs.maskBox.onmouseup = null;
      };
      if (e.preventDefault) {
        e.preventDefault();
      } else {
        return false;
      }
    },

    option(row, value, total) {
      let data = [
        {
          label: "推荐科目",
          options: row,
        },
        {
          label: "科目",
          options: value,
        },
        {
          label: "合计项",
          options: total,
        },
      ];
      return data;
    },
    // 非合计项
    changeOption(row, value, recommend, no_total, total) {
      let obj = {};
      let data = recommend.concat(no_total, total);
      obj = data.find((item) => {
        return item["subject"] === value;
      });

      row.subject = obj.subject;
      row.name = obj.name;
      this.checkTotal();
    },
    // 合计项
    changeOptionTotal(row, value, recommend, no_total, total) {
      let obj = {};
      let data = recommend.concat(no_total, total);
      obj = data.find((item) => {
        return item["name"] === value;
      });
      row.subject = obj.subject;
      row.name = obj.name;
      this.checkTotal();
    },
    changeValue1() {
      this.checkTotal();
    },
    changeValue() {
      this.checkTotal();
    },

    formula(obj) {
      return [obj];
    },
    // 合计检查
    checkTotal() {
      // 接口调用
      checkParam(this.dataList).then(({ data }) => {
        this.dataList = data.data;
      });
    },
    // 删除
    deleteClick(row, data) {
      data.map((item, index) => {
        // console.log(item);
        if (row.template_name == item.template_name) {
          data.splice(index, 1);
        }
      });
    },
    // 重置
    reset(fs_type) {
      if (fs_type == "资产负债表") {
        this.dataList.content.fb = sesstion.getLocalData("OCRList").content.fb;
      } else if (fs_type == "利润表") {
        this.dataList.content.fp = sesstion.getLocalData("OCRList").content.fp;
      } else {
        this.dataList.content.fc = sesstion.getLocalData("OCRList").content.fc;
      }
    },
    // 确认数据
    confirm(fs_type, is_trim) {
      if (fs_type == "资产负债表") {
        if (is_trim) {
          this.FB = true;
          this.$message({
            type: "success",
            message: "确认成功!",
          });
          if (this.FB && this.FP) {
            this.check = false;
          }
        } else {
          this.$confirm("当前报表未配平，是否确认数据?", "提示", {
            confirmButtonText: "确 定",
            cancelButtonText: "取 消",
            // type: "warning",
          })
            .then(() => {
              this.FB = true;
              this.$message({
                type: "success",
                message: "确认成功!",
              });
              if (this.FB && this.FP) {
                this.check = false;
              }
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消确认",
              });
            });
        }
      } else if (fs_type == "利润表") {
        if (is_trim) {
          this.FP = true;
          this.$message({
            type: "success",
            message: "确认成功!",
          });
          if (this.FB && this.FP) {
            this.check = false;
          }
        } else {
          this.$confirm("当前报表未配平，是否确认数据?", "提示", {
            confirmButtonText: "确 定",
            cancelButtonText: "取 消",
            // type: "warning",
          })
            .then(() => {
              this.FP = true;
              this.$message({
                type: "success",
                message: "确认成功!",
              });
              if (this.FB && this.FP) {
                this.check = false;
              }
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消确认",
              });
            });
        }
      } else {
        if (is_trim) {
          this.FC = true;
          this.$message({
            type: "success",
            message: "确认成功!",
          });
        } else {
          this.$confirm("当前报表未配平，是否确认数据?", "提示", {
            confirmButtonText: "确 定",
            cancelButtonText: "取 消",
            // type: "warning",
          })
            .then(() => {
              this.FC = true;
              this.$message({
                type: "success",
                message: "确认成功!",
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消确认",
              });
            });
        }
      }
      // console.log(this.FB, this.FP);
    },

    // 取消
    escClick() {
      sesstion.removeLocalData("OCRList");
      this.$router.back();
    },
    anewUpload() {
      sesstion.removeLocalData("OCRList");
      this.$router.back();
    },
    onSubmit() {
      // 优先平衡校验
      checkBalance(this.dataList).then(({ data }) => {
        // console.log(data);
        if (data.check_status == false) {
          this.checkDialog = true;
          let desc = data.data.replaceAll("//", "<br/>");
          this.checkDesc = desc;
        } else {
          this.checkDialog = true;
          this.checkDesc = data.data + ",确认提交数据？";
        }
      });
    },
    // 提交
    Submit() {
      save(this.dataList).then(({ data }) => {
        // console.log(data);
        if (data.code == 200) {
          this.checkDialog = false;
          this.$message.success("提交成功");
          sesstion.removeLocalData("OCRList");
          this.escClick();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
#OCR {
  background: #ebf2f8;
  .OCRBox {
    padding: 0px 60px 20px 60px;
  }

  .OCRTop {
    padding-top: 15px;
    padding-left: 25px;
    background: #fff;
    .title {
      color: #333;
      font-size: 18px;
      font-weight: 550;
    }

    .TitleText {
      font-size: 16px;
      color: #999;
    }

    .grid-content {
      color: #333;
      font-size: 16px;
    }
  }
  .OCRcont {
    width: 100%;

    margin-top: 20px;
    padding: 15px 25px;
    background: #fff;
    .title {
      color: #333;
      font-size: 18px;
      font-weight: 550;
    }
    .imgBox {
      width: 29%;
      //   height: 550px;
      border-radius: 4px;
      .imgCont {
        width: 100%;
        height: 550px;
        position: relative;
        margin: 0 auto;
        overflow: hidden;
        background: rgb(224, 224, 224);
        img {
          position: absolute;
          cursor: pointer;
        }
      }
    }
    .tableBox {
      position: relative;
      width: 69%;
      height: 650px;
      /deep/.el-tabs__item {
        font-size: 16px;
      }
      .text {
        z-index: 99;
        position: absolute;
        top: 10px;
        right: 0px;
        display: flex;
        align-items: center;
        .rowTitle {
          display: flex;
          align-items: center;
          margin-right: 10px;
          .textbox {
            width: 8px;
            height: 8px;
            border-radius: 50%;

            margin-right: 5px;
          }
          .red {
            background: #f14c5d;
            color: #f14c5d;
          }
          .correct {
            color: #56bd81;
            background: #56bd81;
          }
        }
      }
    }
  }
  .OCRfooter {
    width: 100%;
    background: #fff;
    padding: 20px 60px 20px 0px;
    text-align: right;
  }
  /deep/.el-input-number .el-input__inner {
    text-align: right !important;
  }
  /deep/.el-input__inner {
    font-size: 14px;
  }
  /deep/ .el-dialog__footer {
    text-align: center;
  }
  /deep/ .el-button {
    font-size: 14px !important;
  }
  /deep/.el-loading-spinner .el-loading-text {
    color: #fff !important;
    margin: 3px 0;
    font-size: 14px;
  }
  /deep/ .err {
    .el-input__inner {
      color: #f14c5d;
      font-weight: 550;
    }
  }
  /deep/ .suc {
    .el-input__inner {
      color: #56bd81;
      font-weight: 550;
    }
  }
}
</style>